import React from 'react';
import { Typography } from 'cwb-react';
import styled from 'styled-components';
import Breadcrumbs from './Breadcrumbs';

function Header({ breadcrumbs, content, title }) {
  return (
    <Root>
      <StyledDiv>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Typography component="h1" variant="h2">
          {title}
        </Typography>
      </StyledDiv>
      {content}
    </Root>
  );
}

const Root = styled.div`
  height: 95px;
  display: flex;
  justify-content: space-between;
  padding: ${(p) => p.theme.spacing(2, 6)};
  box-shadow: inset 0 -1px 0 #e7e7f0;
  box-sizing: border-box;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`;

export default Header;
