import React from 'react';
import { Link } from 'cwb-react';
import { Link as ReactRouterLink } from 'react-router-dom';
import styled from 'styled-components';

function Breadcrumbs({ breadcrumbs }) {
  return (
    <div>
      {breadcrumbs.map(({ name, path }, index) => (
        <StyledLink key={index} as={ReactRouterLink} to={path}>
          {name}
        </StyledLink>
      ))}
    </div>
  );
}

const StyledLink = styled(Link)`
  color: #758196;
`;

export default Breadcrumbs;
