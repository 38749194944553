import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Checkbox, Typography, moment } from 'cwb-react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Menu, Item, useContextMenu } from 'components/ContextMenu';
import ArchiveIcon from 'images/archive.svg';
import ArchiveIconHover from 'images/archive_hover.svg';
import { ITEM_PROJECT } from 'data/types';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useFolders, useProjects } from 'contexts';

function TableRow({
  project,
  isProjectSelected,
  handleCheckProject,
  selectedProject,
  setSelected
}) {
  const [isHighlighted, setIsHighlighted] = useState(false);
  const { setOpenFolder, setOpenTimer } = useFolders();
  const { dragging, setDragging, setArchiveModelOpen, setArchiveProject } =
    useProjects();
  const [archiveIcon, setArchiveIcon] = useState(ArchiveIcon);

  const { t } = useTranslation();
  const { show } = useContextMenu({ id: `project-${project.id}` });

  const handleContextMenu = (e) => {
    e.preventDefault();
    setIsHighlighted(true);
    show(e, {
      props: { project }
    });
  };

  const handleHidden = () => {
    setIsHighlighted(false);
  };

  const rename = ({ props }) => {
    const { project } = props;
    console.log('rename', project);
  };

  const openLocation = ({ props }) => {
    const { project } = props;
    if (project.folderId !== null) {
      setOpenFolder(project.folderId);
      setOpenTimer(moment.moment());
    }
  };

  const archive = ({ props }) => {
    const { project } = props;
    console.log('archive', project);
    setArchiveModelOpen(true);
    setArchiveProject({ title: project.title, id: project.id });
  };

  const [{ isDragging }, drag, preview] = useDrag({
    type: ITEM_PROJECT,
    item: { selectedProject },
    canDrag: (monitor) => {
      if (selectedProject.length === 0) {
        return false;
      }
      return true;
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      // When dropped on a compatible target, do something
      if (dropResult) {
        setSelected([]);
        setDragging(false);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  useEffect(() => {
    setDragging(isDragging);
  }, [isDragging, setDragging]);

  useEffect(() => {
    preview(getEmptyImage(), {
      captureDraggingState: true
    });
  }, [preview]);

  return (
    <>
      <StyledRow
        isHighlighted={isHighlighted}
        selected={isProjectSelected(project.id)}
        onContextMenu={(e) => handleContextMenu(e)}
        ref={drag}
      >
        <td>
          <Checkbox
            checked={isProjectSelected(project.id)}
            onChange={(e) => handleCheckProject(e, project)}
          />
        </td>
        <td>
          <Typography>
            {moment.moment(project.dateCreated).format('MMM D, YY')}
          </Typography>
        </td>
        <td>
          <Typography
            color={dragging && isProjectSelected(project.id) ? 'lightGrey' : ''}
          >
            {project.title}
          </Typography>
        </td>
        <td>
          <Typography>{project.numRoles}</Typography>
        </td>
        <td>
          <Typography>{moment.moment().format('MMM D, YY')}</Typography>
        </td>
      </StyledRow>
      {createPortal(
        <Menu id={`project-${project.id}`} onHidden={handleHidden}>
          <Item onClick={rename}>{t('Rename')}</Item>
          <Item onClick={openLocation} disabled={project.folderId === null}>
            {t('Open Project Location')}
          </Item>
          <Item
            onClick={archive}
            onMouseEnter={() => {
              setArchiveIcon(ArchiveIconHover);
            }}
            onMouseLeave={() => {
              setArchiveIcon(ArchiveIcon);
            }}
          >
            <Icon src={archiveIcon} />
            {t('Archive')}
          </Item>
        </Menu>,
        document.getElementById('project-menu-anchor')
      )}
    </>
  );
}

const StyledRow = styled.tr`
  &&& {
    ${(p) =>
      p.selected &&
      css`
        background-color: #d5eefe;
      `}
  }

  ${(p) =>
    p.isHighlighted &&
    css`
      box-shadow: inset 0 0 0 1px ${(p) => p.theme.palette.button.normal};
    `}
`;

const Icon = styled.img`
  margin-right: ${(p) => p.theme.spacing(1)};
`;

export default TableRow;
