import React, { createContext, useCallback, useContext, useState } from 'react';

const initialProjects = [
  {
    id: 1,
    title: 'Better Call Saul S01E01',
    dateCreated: '2021-04-19T16:34:26-07:00',
    numRoles: 3,
    auditions: [],
    folderId: 3
  },
  {
    id: 2,
    title: 'The Boys S01E01',
    dateCreated: '2021-03-20T12:34:26-07:00',
    numRoles: 2,
    auditions: [],
    folderId: 5
  },
  {
    id: 3,
    title: 'The Boys S01E02',
    dateCreated: '2021-02-20T17:34:26-07:00',
    numRoles: 3,
    auditions: [],
    folderId: 5
  },
  {
    id: 4,
    title: 'The Boys S02E01',
    dateCreated: '2021-02-22T17:34:26-07:00',
    numRoles: 5,
    auditions: [],
    folderId: 6
  },
  {
    id: 5,
    title: 'The Boys S02E02',
    dateCreated: '2021-03-15T17:21:26-07:00',
    numRoles: 10,
    auditions: [],
    folderId: 6
  },
  {
    id: 6,
    title: 'The Boys roles',
    dateCreated: '2021-01-15T09:34:26-07:00',
    numRoles: 2,
    auditions: [],
    folderId: 4
  },
  {
    id: 7,
    title: 'Dark Window S01 roles',
    dateCreated: '2020-04-20T11:25:20-07:00',
    numRoles: 1,
    auditions: [],
    folderId: 8
  },
  {
    id: 8,
    title: 'Dark Window S01E01',
    dateCreated: '2021-02-12T15:34:26-07:00',
    numRoles: 2,
    auditions: [],
    folderId: 10
  },
  {
    id: 9,
    title: 'The 100 roles',
    dateCreated: '2020-07-30T12:14:16-07:00',
    numRoles: 5,
    auditions: [],
    folderId: 11
  },
  {
    id: 10,
    title: 'The Irregulars',
    dateCreated: '2021-03-23T15:34:26-07:00',
    numRoles: 0,
    auditions: [],
    folderId: null
  },
  {
    id: 11,
    title: 'Planet of the Apes',
    dateCreated: '2021-03-28T16:14:27-07:00',
    numRoles: 1,
    auditions: [],
    folderId: null
  },
  {
    id: 12,
    title: 'Ozark finale',
    dateCreated: '2021-03-30T07:34:26-07:00',
    numRoles: 2,
    auditions: [],
    folderId: null
  },
  {
    id: 13,
    title: 'Snowpiercer',
    dateCreated: '2021-04-02T11:34:26-07:00',
    numRoles: 3,
    auditions: [],
    folderId: null
  },
  {
    id: 14,
    title: 'Euphoria',
    dateCreated: '2021-04-05T12:34:26-07:00',
    numRoles: 4,
    auditions: [],
    folderId: null
  },
  {
    id: 15,
    title: 'Brooklyn Nine Nine pilot',
    dateCreated: '2021-04-08T13:34:26-07:00',
    numRoles: 5,
    auditions: [],
    folderId: null
  }
];

const ProjectContext = createContext({});

export const ProjectProvider = ({ children }) => {
  const [projects, setProjects] = useState([]);
  const [total, setTotal] = useState(0);
  const [initial, setInitial] = useState(initialProjects);
  const [dragging, setDragging] = useState(false);
  const [archiveModalOpen, setArchiveModelOpen] = useState(false);
  const [archiveProject, setArchiveProject] = useState(null);
  const [search, setSearch] = useState('');

  const fetchProjects = useCallback(
    (limit, offset, folders) => {
      if (search === '') {
        let selectedProjects = [];
        if (folders !== null) {
          for (let i = 0; i < folders.length; i++) {
            const result = initial.filter(
              (item) => item.folderId === folders[i]
            );
            selectedProjects = selectedProjects.concat(result);
          }
        } else {
          selectedProjects = initial;
        }

        const response = {
          results: selectedProjects.slice(offset, offset + limit),
          total_results: selectedProjects.length
        };

        setProjects(response.results);
        setTotal(response.total_results);
      } else {
        let selectedProjects = initial.filter((item) =>
          item.title.toLowerCase().includes(search.toLowerCase())
        );
        const response = {
          results: selectedProjects.slice(offset, offset + limit),
          total_results: selectedProjects.length
        };
        setProjects(response.results);
        setTotal(response.total_results);
      }
    },
    [initial, search]
  );

  const updateProjectFolder = (items, id) => {
    const idArray = items.map((el) => el.id);
    setInitial((initial) => {
      return initial.map((project) => {
        if (idArray.includes(project.id)) {
          return {
            ...project,
            folderId: id
          };
        } else {
          return project;
        }
      });
    });
  };

  const unsortProjects = (idArray) => {
    setInitial((initial) => {
      return initial.map((project) => {
        if (idArray.includes(project.folderId)) {
          return {
            ...project,
            folderId: null
          };
        } else {
          return project;
        }
      });
    });
  };

  const archive = (id) => {
    setInitial((initial) => {
      return initial.filter((project) => project.id !== id);
    });
  };

  return (
    <ProjectContext.Provider
      value={{
        projects,
        total,
        fetchProjects,
        updateProjectFolder,
        dragging,
        setDragging,
        unsortProjects,
        archiveModalOpen,
        setArchiveModelOpen,
        archiveProject,
        setArchiveProject,
        archive,
        setSearch
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export function useProjects() {
  return useContext(ProjectContext);
}
