import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Typography, toast } from 'cwb-react';
import FolderAdd from 'images/folder_add.svg';
import FileAdd from 'images/file_add.svg';
import DeleteFolder from 'images/delete.svg';
import FolderSVG from 'images/folder.svg';
import { useFolders, useProjects } from 'contexts';

import { useDrag, useDrop } from 'react-dnd';
import { ITEM_FOLDER, ITEM_PROJECT } from 'data/types';
import { Menu, Item, useContextMenu } from 'react-contexify';

const ThirdLevelFolder = ({ folder, ancestor, parent }) => {
  const { t } = useTranslation();
  const domRef = React.useRef(null);
  const {
    setSelectedFolder,
    selectedFolder,
    setDisabledAdd,
    setRenameModalOpen,
    setRenameFolder,
    setDeleteFolder,
    setDeleteModelOpen,
    openFolder,
    setExpandedFolder,
    openTimer,
    setSelectedFolderPath,
    getNameTop,
    getNameSub,
    getIncludeFolders,
    setIncludeFolders
  } = useFolders();
  const { updateProjectFolder, setSearch } = useProjects();
  const selected = selectedFolder === folder.id;
  const selectFolder = () => {
    const { id } = folder;
    if (selectedFolder !== id) {
      setSelectedFolder(id);
      setSelectedFolderPath(folder.id);
      getIncludeFolders(id);
      setDisabledAdd(true);
    } else {
      setSelectedFolder(null);
      setSelectedFolderPath(null);
      setIncludeFolders(null);
      setDisabledAdd(false);
    }
    setSearch('');
  };
  const menuId = folder.id;
  const { show } = useContextMenu({
    id: menuId
  });
  const handleContextMenu = (e) => {
    e.preventDefault();
    show(e, {
      props: {
        key: 'value'
      }
    });
  };
  const handleRename = () => {
    const id = folder.id;
    const name = folder.name;
    setRenameModalOpen(true);
    setRenameFolder({
      id,
      name,
      parent,
      ancestor
    });
  };
  const handleAddProject = () => {
    const id = folder.id;
    console.log(id, 'addProject');
  };

  const handleDelete = () => {
    const id = folder.id;
    const name = folder.name;
    setDeleteModelOpen(true);
    setDeleteFolder({
      id,
      name,
      parent,
      ancestor
    });
  };

  useEffect(() => {
    if (openFolder === folder.id) {
      if (selectedFolder !== folder.id) {
        selectFolder();
      }
      let array = [];
      if (parent !== null && ancestor !== null) {
        array.push(parent);
        array.push(ancestor);
        setExpandedFolder(array);
      }
    }
  }, [openFolder, folder.id, parent, ancestor, openTimer]);

  const notifyMove = (items, id) => {
    updateProjectFolder(items.selectedProject, folder.id);
    launchToast(items, id);
  };

  const undoMove = (items) => {
    const { selectedProject } = items;
    let map = new Map();
    for (let i = 0; i < selectedProject.length; i++) {
      const { folderId } = selectedProject[i];
      if (map.has(folderId)) {
        const projectArray = map.get(folderId);
        projectArray.push(selectedProject[i]);
        map.set(folderId, projectArray);
      } else {
        const projectArray = [];
        projectArray.push(selectedProject[i]);
        map.set(folderId, projectArray);
      }
    }
    map.forEach((value, key) => {
      updateProjectFolder(value, key);
    });
  };

  const launchToast = (items, id) => {
    let toastId;
    const itemLength = items.selectedProject.length;
    let path = '';
    const ancestorName = getNameTop(ancestor);
    const parentName = getNameSub(ancestor, parent);
    path = `${ancestorName}/${parentName}/${folder.name}`;
    const toastInner = () => {
      return (
        <ToastContainer>
          {t('moveProjectNotification', { itemLength, path })}
          <UndoButton
            onClick={() => {
              undoMove(items);
              toast.update(toastId, {
                render: t('Action Undone'),
                type: toast.TYPE.INFO,
                autoClose: 2000
              });
            }}
          >
            {t('UNDO')}
          </UndoButton>
        </ToastContainer>
      );
    };

    toastId = toast.info(toastInner);
  };

  const [{ isOver }, drop] = useDrop({
    accept: [ITEM_FOLDER, ITEM_PROJECT],
    drop: (item, monitor) => {
      if (item.type === 'folder') {
        return {
          success: false
        };
      } else {
        notifyMove(item, folder.id);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  const [, drag] = useDrag({
    type: ITEM_FOLDER,
    item: { folder, parent, ancestor, childLevel: 1, type: 'folder' },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  drop(drag(domRef));

  return (
    <>
      <Container
        selected={selected}
        onClick={selectFolder}
        ref={domRef}
        onContextMenu={handleContextMenu}
        isOver={isOver}
      >
        <FolderIcon src={FolderSVG} />
        <Title selected={selected}>
          <Typography color={selected || isOver ? 'white' : '#0A1F44'}>
            {folder.name}
          </Typography>
        </Title>
      </Container>
      <StyledMenu id={menuId}>
        <StyledItem onClick={handleRename}>
          <Typography variant="caption">Rename</Typography>
        </StyledItem>
        <Item disabled={true}>
          <FolderOperation src={FolderAdd} />
          <Typography variant="caption">New Folder in &nbsp;</Typography>
          <Typography variant="captionBold">"{folder.name}"</Typography>
        </Item>
        <StyledItem onClick={handleAddProject}>
          <FolderOperation src={FileAdd} />
          <Typography variant="caption">New Project in &nbsp;</Typography>
          <Typography variant="captionBold">"{folder.name}"</Typography>
        </StyledItem>
        <Item onClick={handleDelete}>
          <FolderOperation src={DeleteFolder} />
          <Typography variant="caption">Delete</Typography>
        </Item>
      </StyledMenu>
    </>
  );
};

const Container = styled.div`
  width: 350px;
  padding-left: 137px;
  display: flex;
  align-items: center;
  height: 26px;
  ${(p) =>
    p.selected || p.isOver
      ? `background-color: #0584D7;;`
      : `background-color: white;`}
`;

const FolderIcon = styled.img`
  width: 16px;
  width: 16px;
  margin-right: 5px;
`;

const Title = styled.span`
  display: block;
  ${(p) => (p.selected ? `color: white;` : `color: #0A1F44;`)}
`;

const FolderOperation = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  white-space: nowrap;
  overflow: hidden;
`;

const UndoButton = styled.button`
  color: #0f9bf0;
  background-color: white;
  border: none;
  margin-left: 16px;
  font-size: 14px;
  font-weight: 600;
`;

const StyledMenu = styled(Menu)`
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.12));
`;

const StyledItem = styled(Item)`
  border-bottom: 1px solid #f1f2f4;
`;

export default ThirdLevelFolder;
