import { forwardRef, useImperativeHandle } from 'react';
import { DropTarget } from 'react-dnd';
import { ITEM_FOLDER } from 'data/types';
import { useFolders } from 'contexts';

const Wrapper = forwardRef(function Wrapper(
  { connectDropTarget, children },
  ref
) {
  const { confirmOuterMove } = useFolders();
  useImperativeHandle(
    ref,
    () => ({
      onDrop: (onChild, item) => {
        const target = {
          parent: null,
          ancestor: null
        };
        if (!onChild) {
          confirmOuterMove(item, target);
        }
      }
    }),
    []
  );
  return connectDropTarget(<div>{children}</div>);
});
export default DropTarget(
  [ITEM_FOLDER],
  {
    drop(props, monitor, component) {
      const item = monitor.getItem();

      if (item.type === 'folder') {
        if (!component) {
          return;
        }
        const hasDroppedOnChild = monitor.didDrop();
        if (hasDroppedOnChild) {
          return;
        }
        component.onDrop(hasDroppedOnChild, item);
      } else {
      }
    }
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true })
  })
)(Wrapper);
