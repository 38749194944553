import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Header from 'components/Header';
import Page from 'components/Page';
import HeaderContent from './components/HeaderContent';
import ProjectTable from './components/ProjectTable';
import Sidebar from './components/Sidebar';

function Dashboard() {
  const { t } = useTranslation();

  const breadcrumbs = [{ name: t('SAM Home'), path: '/' }];

  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        content={<HeaderContent />}
        title={t('SAM Home')}
      />
      <StyledPage>
        <Sidebar />
        <ProjectTable />
      </StyledPage>
    </>
  );
}

const StyledPage = styled(Page)`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
`;

export default Dashboard;
