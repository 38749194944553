import React, { useState, useEffect } from 'react';
import { Checkbox, Typography } from 'cwb-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useProjects, useFolders } from 'contexts';
import TableFooter from './components/TableFooter';
import TableHeader from './components/TableHeader';
import TableRow from './components/TableRow';

function ProjectTable() {
  const [selected, setSelected] = useState([]);

  const { t } = useTranslation();
  const { projects } = useProjects();
  const { selectedFolder } = useFolders();

  useEffect(() => {
    setSelected([]);
  }, [selectedFolder]);

  const handleCheckAll = (e) => {
    const toAdd = [];
    const toRemove = [];
    if (e.target.checked) {
      projects.forEach((project) => {
        if (!selected.some((item) => item.id === project.id)) {
          toAdd.push(project);
        }
      });
      setSelected((selected) => [...selected, ...toAdd]);
    } else {
      projects.forEach((project) => {
        if (selected.some((item) => item.id === project.id)) {
          toRemove.push(project);
        }
      });
      setSelected((selected) =>
        selected.filter((project) => {
          return !toRemove.some((item) => item.id === project.id);
        })
      );
    }
  };

  const isProjectSelected = (projectId) => {
    return selected.some((project) => project.id === projectId);
  };

  const handleCheckProject = (e, project) => {
    if (e.target.checked) {
      setSelected((selected) => [...selected, project]);
    } else {
      setSelected((selected) =>
        selected.filter((item) => {
          return item.id !== project.id;
        })
      );
    }
  };

  const tableHeaders = [
    <Checkbox
      checked={
        projects.every((project) =>
          selected.some((s) => s.id === project.id)
        ) && projects.length !== 0
      }
      onChange={handleCheckAll}
    />,
    <Typography variant="bodyBold">{t('Date Created')}</Typography>,
    <Typography variant="bodyBold">{t('Project Title')}</Typography>,
    <Typography variant="bodyBold">{t('Roles')}</Typography>,
    <Typography variant="bodyBold">{t('Auditions')}</Typography>
  ];

  return (
    <StyledDiv>
      <TableHeader selected={selected} clearSelection={() => setSelected([])} />
      <StyledTable>
        <thead>
          <tr>
            {tableHeaders.map((content, index) => (
              <th key={index}>{content}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {projects.map((project) => (
            <TableRow
              key={project.id}
              project={project}
              isProjectSelected={isProjectSelected}
              handleCheckProject={handleCheckProject}
              selectedProject={selected}
              setSelected={setSelected}
            />
          ))}
        </tbody>
      </StyledTable>
      <TableFooter />
      <div id="project-menu-anchor" />
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  margin: ${(p) => p.theme.spacing(2, 'auto', 0)};
`;

const StyledTable = styled.table`
  width: 100%;
  border: 2px solid ${(p) => p.theme.palette.grey[2]};
  border-collapse: unset;
  border-radius: 4px;
  border-spacing: 0;

  & > thead {
    background-color: ${(p) => p.theme.palette.grey[1]};
    box-shadow: inset 0 -1px 0 #e7e7f0;

    & th:first-child {
      width: 5%;
      border-top-left-radius: 2px;
    }

    & th:nth-child(2) {
      width: 15%;
    }

    & th:nth-child(4) {
      width: 10%;
    }

    & th:last-child {
      width: 15%;
      border-top-right-radius: 2px;
    }
  }

  & > tbody > tr {
    height: 24px;

    &:nth-child(even) {
      background-color: ${(p) => p.theme.palette.grey[1]};
    }

    &:last-child > td:first-child {
      border-bottom-left-radius: 2px;
    }

    &:last-child > td:last-child {
      border-bottom-right-radius: 2px;
    }
  }

  & th,
  td {
    padding-left: ${(p) => p.theme.spacing(2)};

    &:last-child {
      padding-right: ${(p) => p.theme.spacing(2)};
    }
  }

  & th {
    padding-top: ${(p) => p.theme.spacing(2)};
    padding-bottom: ${(p) => p.theme.spacing(2)};
  }
`;

export default ProjectTable;
