import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { FolderProvider, ProjectProvider } from 'contexts';
import CWBTheme from 'theme';
import Router from './Router';
import './i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-contexify/dist/ReactContexify.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'cwb-react';
import styled from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ItemsDragLayer from 'components/dragLayer';

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    width: fit-content;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

ReactDOM.render(
  <DndProvider backend={HTML5Backend}>
    <ItemsDragLayer />
    <ThemeProvider theme={CWBTheme}>
      <FolderProvider>
        <ProjectProvider>
          <Router />
          <StyledToastContainer />
        </ProjectProvider>
      </FolderProvider>
    </ThemeProvider>
  </DndProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
