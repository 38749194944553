import React from 'react';
import { useDragLayer } from 'react-dnd';
import styled from 'styled-components';
import MoveSVG from 'images/move.svg';

const getItemStyles = (currentOffset) => {
  if (!currentOffset) {
    return {
      display: 'none'
    };
  }

  const { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform
  };
};

export default function FieldDragLayer(props) {
  const { itemType, isDragging, item, currentOffset } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging()
    })
  );

  const Items = (projects) => {
    const items = projects.project;
    const length = items.length;
    return (
      <Container>
        <MoveIcon src={MoveSVG} />
        <MoveText>
          Move <b>{length}</b> Projects
        </MoveText>
      </Container>
    );
  };

  const renderItem = (type, item) => {
    switch (type) {
      case 'ITEM_PROJECT':
        return <Items project={item.selectedProject} />;
      default:
        return null;
    }
  };
  if (!isDragging) {
    return null;
  }

  return (
    <Layer>
      <div style={getItemStyles(currentOffset)}>
        {renderItem(itemType, item)}
      </div>
    </Layer>
  );
}

const Layer = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  width: 156px;
  height: 33px;
  background: #f8f9fb;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MoveIcon = styled.img`
  position: absolute;
  top: -10px;
  left: -10px;
  width: 26px;
  height: 26px;
`;

const MoveText = styled.p`
  color: #0a1f44;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
`;
