import { Menu, Item, useContextMenu } from 'react-contexify';
import styled from 'styled-components';

const StyledMenu = styled(Menu).attrs({
  animation: false
})`
  .react-contexify__item__content {
    color: ${(p) => p.theme.typography.color.darkGrey};
    font-size: ${(p) => p.theme.typography.fontSize.body};
    font-weight: ${(p) => p.theme.typography.fontWeight.body};
    line-height: ${(p) => p.theme.typography.lineHeight.body};
  }

  .react-contexify__item:not(.react-contexify__item--disabled):hover
    > .react-contexify__item__content,
  .react-contexify__item:not(.react-contexify__item--disabled):focus
    > .react-contexify__item__content {
    color: ${(p) => p.theme.palette.common.white};
    background-color: ${(p) => p.theme.palette.button.normal};
  }
`;

export { StyledMenu as Menu, Item, useContextMenu };
