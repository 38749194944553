import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useFolders } from 'contexts';
import { useTranslation } from 'react-i18next';
import StructureFolder from './StructureFolder';
import { Typography, TextInput } from 'cwb-react';
import Checkmark from 'images/checkmark.svg';
import FolderSVG from 'images/folder.svg';
import SearchSVG from 'images/searchIcon.svg';
import SortSVG from 'images/sort.svg';
import DropWrapper from './dropWrapper';

const Folders = () => {
  const { t } = useTranslation();
  const { folders, addToFolder, addTimer, confirmAdd } = useFolders();
  const dragRef = React.useRef(null);
  const domRef = React.useRef(null);
  const [searchText, setSearchText] = useState('');
  const [sortMenuOpen, setSortMenuOpen] = useState(false);
  const [sortOption, setSortOption] = useState(1);
  const [addNewOpen, setAddNewOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState(t('New Folder'));
  const inputRef = React.useRef(null);

  const inputBlurOut = () => {
    if (addNewOpen) {
      if (newFolderName !== '') {
        confirmAdd({
          name: newFolderName,
          parent: null,
          ancestor: null
        });
        setAddNewOpen(false);
      }
    }
  };

  useEffect(() => {
    if (addToFolder === -1) {
      setAddNewOpen(true);
    }
  }, [addToFolder, addTimer]);

  useEffect(() => {
    if (addNewOpen === true) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [addNewOpen]);

  const searchFolder = (e) => {
    setSearchText(e.target.value);
  };
  const toggleSortMenu = (event) => {
    event.stopPropagation();
    setSortMenuOpen(!sortMenuOpen);
  };
  const changeSortOption = (value) => {
    setSortOption(value);
  };

  const inputOperation = (event) => {
    if (event.key === 'Enter') {
      if (newFolderName !== '') {
        confirmAdd({
          name: newFolderName,
          parent: null,
          ancestor: null
        });
        setAddNewOpen(false);
      }
    }
  };
  let showFolders = folders;
  if (searchText !== '') {
    showFolders = showFolders.filter((item) =>
      item.name.toUpperCase().includes(searchText.toUpperCase())
    );
  }
  if (sortOption === 1) {
    showFolders = showFolders.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  } else {
    showFolders = showFolders.sort(function (a, b) {
      if (a.name < b.name) {
        return 1;
      }
      if (a.name > b.name) {
        return -1;
      }
      return 0;
    });
  }

  return (
    <Container>
      <HeadContainer>
        <Typography variant="h3">{t('Folder')}</Typography>
        <Sort onClick={toggleSortMenu}>
          <SortIcon src={SortSVG} />
          <Typography variant="bodyBold">{t('Sort By')}</Typography>
        </Sort>
      </HeadContainer>
      {sortMenuOpen && (
        <SortMenu ref={domRef}>
          <SortOption
            onClick={() => {
              changeSortOption(1);
            }}
          >
            <Typography variant={sortOption === 1 ? 'captionBold' : 'caption'}>
              {t('A - Z')}
            </Typography>
            {sortOption === 1 && <CheckIcon src={Checkmark} />}
          </SortOption>
          <SortOption
            onClick={() => {
              changeSortOption(2);
            }}
          >
            <Typography variant={sortOption === 2 ? 'captionBold' : 'caption'}>
              {t('Z - A')}
            </Typography>
            {sortOption === 2 && <CheckIcon src={Checkmark} />}
          </SortOption>
        </SortMenu>
      )}
      <StyledSearchInput
        placeholder="Search"
        onChange={searchFolder}
        value={searchText}
        startAdornment={<img src={SearchSVG} alt="" />}
      />
      <DropWrapper>
        <FolderContainer ref={dragRef}>
          {addNewOpen && (
            <AddNew>
              <FolderIcon src={FolderSVG} />
              <FolderInput
                onChange={(e) => {
                  setNewFolderName(e.target.value);
                }}
                value={newFolderName}
                ref={inputRef}
                onKeyPress={inputOperation}
                onBlur={inputBlurOut}
              />
            </AddNew>
          )}
          {showFolders.map((item) => (
            <StructureFolder
              folder={item}
              key={item.id}
              level={1}
              parent={null}
            />
          ))}
        </FolderContainer>
      </DropWrapper>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  padding-bottom: 83px;
  position: relative;
`;

const HeadContainer = styled.div`
  margin-left: 47px;
  margin-right: 23px;
  display: flex;
  margin-top: 16px;
  margin-bottom: 8px;
  justify-content: space-between;
`;

const Sort = styled.div`
  height: 24px;
  display: flex;
  cursor: pointer;
`;

const SortIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-top: 2px;
  margin-right: 5px;
`;

const StyledSearchInput = styled(TextInput)`
  margin-left: 47px;
  margin-right: 23px;
  margin-bottom: 12px;
  max-width: 281px !important;
`;

const FolderContainer = styled.div`
  max-height: calc(100vh - 330px);
  height: calc(100vh - 330px);
  overflow-y: scroll;
`;

const SortMenu = styled.div`
  position: absolute;
  top: 25px;
  left: 218px;
  width: 100px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  z-index: 100;
  padding: 9px 8px;
`;

const SortOption = styled.div`
  height: 22px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
`;

const CheckIcon = styled.img`
  width: 15px;
  height: 15px;
`;

const AddNew = styled.div`
  padding-right: 23px;
  display: flex;
  align-items: center;
  height: 26px;
  padding-left: 47px;
`;

const FolderInput = styled(TextInput)`
  width: 188px;

  input {
    max-height: 23px !important;
  }
`;

const FolderIcon = styled.img`
  width: 16px;
  width: 16px;
  margin-right: 5px;
`;

export default Folders;
