import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Typography, TextInput, toast } from 'cwb-react';
import CaretRightBlack from 'images/caret_right_black.svg';
import CaretRightWhite from 'images/caret_right_white.svg';
import CaretDownBlack from 'images/caret_down_black.svg';
import CaretDownWhite from 'images/caret_down_white.svg';
import FolderAdd from 'images/folder_add.svg';
import FileAdd from 'images/file_add.svg';
import DeleteFolder from 'images/delete.svg';
import FolderSVG from 'images/folder.svg';
import { useFolders, useProjects } from 'contexts';
import { useTranslation } from 'react-i18next';
import ThirdLevelFolder from './ThirdLevelFolder';

import { useDrag, useDrop } from 'react-dnd';
import { ITEM_FOLDER, ITEM_PROJECT } from 'data/types';
import { calcLevel } from 'data/helper';
import { Menu, Item, useContextMenu } from 'react-contexify';

const StructureFolder = ({ folder, level, parent }) => {
  const { t } = useTranslation();

  const childLevel = calcLevel(folder);
  const subFolders = folder.folders;
  const [expanded, setExpanded] = useState(false);
  const [addNewOpen, setAddNewOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState(t('New Folder'));
  const {
    setSelectedFolder,
    selectedFolder,
    setDisabledAdd,
    addToFolder,
    addTimer,
    setRenameModalOpen,
    setRenameFolder,
    setDeleteFolder,
    setDeleteModelOpen,
    confirmAdd,
    confirmMove,
    openFolder,
    setExpandedFolder,
    expandedFolder,
    openTimer,
    setSelectedFolderPath,
    getNameTop,
    getIncludeFolders,
    setIncludeFolders
  } = useFolders();
  const { updateProjectFolder, dragging, setSearch } = useProjects();
  const selected = selectedFolder === folder.id;
  const domRef = React.useRef(null);
  const inputRef = React.useRef(null);

  const inputBlurOut = () => {
    if (addNewOpen) {
      if (newFolderName !== '') {
        if (level === 1) {
          confirmAdd({
            name: newFolderName,
            parent: folder.id,
            ancestor: null
          });
        }
        if (level === 2) {
          confirmAdd({
            name: newFolderName,
            parent: folder.id,
            ancestor: parent
          });
        }
        setNewFolderName(t('New Folder'));
        setAddNewOpen(false);
      }
    }
  };
  const selectFolder = () => {
    const { id } = folder;
    if (selectedFolder !== id) {
      setSelectedFolder(id);
      setSelectedFolderPath(folder.id);
      getIncludeFolders(id);
    } else {
      setSelectedFolder(null);
      setSelectedFolderPath(null);
      setIncludeFolders(null);
    }
    setSearch('');
    setDisabledAdd(false);
  };
  const toggleExpandFolder = (e) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };
  const menuId = folder.id;
  const { show } = useContextMenu({
    id: menuId
  });
  const handleContextMenu = (e) => {
    e.preventDefault();
    show(e, {
      props: {
        key: 'value'
      }
    });
  };
  const handleRename = () => {
    const id = folder.id;
    const name = folder.name;
    setRenameModalOpen(true);
    setRenameFolder({
      id,
      name,
      parent,
      ancestor: null
    });
  };

  const handleAddFolder = () => {
    setExpanded(true);
    setAddNewOpen(true);
  };

  const handleAddProject = () => {
    const id = folder.id;
    console.log(id, 'addProject');
  };

  const handleDelete = () => {
    const id = folder.id;
    const name = folder.name;
    setDeleteModelOpen(true);
    setDeleteFolder({
      id,
      name,
      parent,
      ancestor: null
    });
  };

  const inputOperation = (event) => {
    if (event.key === 'Enter') {
      if (newFolderName !== '') {
        if (level === 1) {
          confirmAdd({
            name: newFolderName,
            parent: folder.id,
            ancestor: null
          });
        }
        if (level === 2) {
          confirmAdd({
            name: newFolderName,
            parent: folder.id,
            ancestor: parent
          });
        }
        setNewFolderName('New Folder');
        setAddNewOpen(false);
      }
    }
  };

  useEffect(() => {
    if (addNewOpen === true) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [addNewOpen]);

  useEffect(() => {
    if (!expanded) {
      setAddNewOpen(false);
    }
  }, [expanded]);

  useEffect(() => {
    if (addToFolder === folder.id) {
      handleAddFolder();
    }
  }, [addToFolder, folder.id, addTimer]);

  useEffect(() => {
    if (openFolder === folder.id) {
      if (selectedFolder !== folder.id) {
        selectFolder();
      }
      let array = [];
      if (parent !== null) {
        array.push(parent);
        setExpandedFolder(array);
      }
    }
  }, [openFolder, folder.id, parent, openTimer]);

  useEffect(() => {
    if (expandedFolder.includes(folder.id)) {
      setExpanded(true);
    }
  }, [expandedFolder, folder.id, openTimer]);

  if (subFolders) {
    subFolders.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  const notifyMove = (items, id) => {
    updateProjectFolder(items.selectedProject, folder.id);
    launchToast(items, id);
  };

  const undoMove = (items) => {
    const { selectedProject } = items;
    let map = new Map();
    for (let i = 0; i < selectedProject.length; i++) {
      const { folderId } = selectedProject[i];
      if (map.has(folderId)) {
        const projectArray = map.get(folderId);
        projectArray.push(selectedProject[i]);
        map.set(folderId, projectArray);
      } else {
        const projectArray = [];
        projectArray.push(selectedProject[i]);
        map.set(folderId, projectArray);
      }
    }
    map.forEach((value, key) => {
      updateProjectFolder(value, key);
    });
  };

  const launchToast = (items, id) => {
    let toastId;

    const itemLength = items.selectedProject.length;
    let path = '';
    if (parent === null) {
      path = folder.name;
    } else {
      path = `${getNameTop(parent)} / ${folder.name}`;
    }
    const toastInner = () => {
      return (
        <ToastContainer>
          {t('moveProjectNotification', { itemLength, path })}
          <UndoButton
            onClick={() => {
              undoMove(items);
              toast.update(toastId, {
                render: t('Action Undone'),
                type: toast.TYPE.INFO,
                autoClose: 2000
              });
            }}
          >
            {t('UNDO')}
          </UndoButton>
        </ToastContainer>
      );
    };

    toastId = toast.info(toastInner);
  };

  const [{ isOver }, drop] = useDrop({
    accept: [ITEM_FOLDER, ITEM_PROJECT],
    drop: (item, monitor) => {
      if (item.type === 'folder') {
        if (item.childLevel + level <= 3) {
          const target = {
            parent: folder.id,
            ancestor: parent
          };
          confirmMove(item, target);
          return {
            success: true
          };
        } else {
          return {
            success: false
          };
        }
      } else {
        notifyMove(item, folder.id);
      }
    },
    /* canDrop:(item, monitor) => {
      if(item.type !== 'folder' && selectedFolder === folder.id){
        return false;
      }
      return true;
    }, */
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  const [{ isDragging }, drag] = useDrag({
    type: ITEM_FOLDER,
    item: { folder, parent, ancestor: null, childLevel, type: 'folder' },
    canDrag: (monitor) => {
      if (childLevel === 3) {
        return false;
      } else {
        return true;
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  drop(drag(domRef));

  const statusOne = !expanded && !selected && !isOver;
  const statusTwo = !expanded && (selected || isOver);
  const statusThree = expanded && !selected && !isOver;
  const statusFour = expanded && (selected || isOver);

  return (
    <Container>
      <TopLevel
        level={level}
        selected={selected}
        onClick={selectFolder}
        ref={domRef}
        onContextMenu={handleContextMenu}
        isOver={isOver}
      >
        {statusOne && (
          <CaretIcon onClick={toggleExpandFolder} src={CaretRightBlack} />
        )}
        {statusTwo && (
          <CaretIcon onClick={toggleExpandFolder} src={CaretRightWhite} />
        )}
        {statusThree && (
          <CaretIcon onClick={toggleExpandFolder} src={CaretDownBlack} />
        )}
        {statusFour && (
          <CaretIcon onClick={toggleExpandFolder} src={CaretDownWhite} />
        )}
        <FolderIcon src={FolderSVG} />
        <Title selected={selected}>
          {(selected || isOver) && !dragging && (
            <Typography color="white">{folder.name}</Typography>
          )}
          {selected && dragging && (
            <Typography color="lightGrey">{folder.name}</Typography>
          )}
          {!selected && isOver && dragging && (
            <Typography color="white">{folder.name}</Typography>
          )}
          {!selected && !isOver && <Typography>{folder.name}</Typography>}
        </Title>
      </TopLevel>
      {addNewOpen && (
        <AddNew level={level}>
          <FolderIcon src={FolderSVG} />
          <FolderInput
            onChange={(e) => {
              setNewFolderName(e.target.value);
            }}
            value={newFolderName}
            onBlur={inputBlurOut}
            ref={inputRef}
            onKeyPress={inputOperation}
          />
        </AddNew>
      )}
      <SubContainer expanded={expanded}>
        {level === 2 &&
          subFolders !== undefined &&
          subFolders.length !== 0 &&
          subFolders.map((item) => (
            <ThirdLevelFolder
              folder={item}
              key={item.id}
              ancestor={parent}
              parent={folder.id}
            />
          ))}
      </SubContainer>
      <SubContainer expanded={expanded}>
        {level === 1 &&
          subFolders !== undefined &&
          subFolders.length !== 0 &&
          subFolders.map((item) => (
            <StructureFolder
              level={2}
              folder={item}
              key={item.id}
              parent={folder.id}
            />
          ))}
      </SubContainer>
      <StyledMenu id={menuId}>
        <StyledItem onClick={handleRename}>
          <Typography variant="caption">Rename</Typography>
        </StyledItem>
        <Item onClick={handleAddFolder}>
          <FolderOperation src={FolderAdd} />
          <Typography variant="caption">New Folder in &nbsp;</Typography>
          <Typography variant="captionBold">"{folder.name}"</Typography>
        </Item>
        <StyledItem onClick={handleAddProject}>
          <FolderOperation src={FileAdd} />
          <Typography variant="caption">New Project in &nbsp;</Typography>
          <Typography variant="captionBold">"{folder.name}"</Typography>
        </StyledItem>
        <Item onClick={handleDelete}>
          <FolderOperation src={DeleteFolder} />
          <Typography variant="caption">Delete</Typography>
        </Item>
      </StyledMenu>
    </Container>
  );
};

const SubContainer = styled.div`
  ${(p) => (p.expanded ? `display:block;` : `display:none;`)}
`;

const FolderInput = styled(TextInput)`
  width: 188px;

  input {
    max-height: 23px !important;
  }
`;

const Container = styled.div`
  width: 350px;
  height: auto;
`;

const TopLevel = styled.div`
  padding-right: 23px;
  display: flex;
  align-items: center;
  height: 26px;
  position: relative;
  ${(p) =>
    p.selected || p.isOver
      ? `background-color: #0584D7;`
      : `background-color: white;`}
  ${(p) => (p.level === 1 ? `padding-left:47px;` : `padding-left:84px;`)}
`;

const CaretIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 5px;
`;

const FolderIcon = styled.img`
  width: 16px;
  width: 16px;
  margin-right: 5px;
`;

const Title = styled.span`
  display: block;
  ${(p) => (p.selected ? `color: white;` : `color: #0A1F44;`)}
`;

const FolderOperation = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

const AddNew = styled.div`
  padding-right: 23px;
  display: flex;
  align-items: center;
  height: 26px;
  ${(p) => (p.level === 1 ? `padding-left:84px;` : `padding-left:137px;`)}
`;

const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  white-space: nowrap;
  overflow: hidden;
`;

const UndoButton = styled.button`
  color: #0f9bf0;
  background-color: white;
  border: none;
  margin-left: 16px;
  font-size: 14px;
  font-weight: 600;
`;

const StyledMenu = styled(Menu)`
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.12));
`;

const StyledItem = styled(Item)`
  border-bottom: 1px solid #f1f2f4;
`;

export default StructureFolder;
