import React from 'react';
import { moment, Button } from 'cwb-react';
import styled from 'styled-components';
import { useFolders } from 'contexts';
import DeleteFolderModal from './components/DeleteFolderModal';
import ArchiveProjectModal from './components/ArchiveProjectModel';
import Folders from './components/Folders';
import RenameModal from './components/RenameModal';
import AddFolderSVG from 'images/add_folder.svg';
import ArrowForwardSVG from 'images/arrow_forward.svg';
import FolderSVG from 'images/folder.svg';

const Sidebar = () => {
  const { disabledAdd, selectedFolder, setAddToFolder, setAddTimer } =
    useFolders();
  const addFolder = (e) => {
    e.stopPropagation();
    if (selectedFolder !== null) {
      setAddToFolder(selectedFolder);
      setAddTimer(moment.moment());
    } else {
      setAddToFolder(-1);
      setAddTimer(moment.moment());
    }
  };

  return (
    <Container>
      <SidebarHeader>
        <IconImg src={ArrowForwardSVG} />
        <IconImg src={FolderSVG} />
      </SidebarHeader>
      <Folders />
      <SidebarFooter>
        <AddNew
          startIcon={<img src={AddFolderSVG} alt="" />}
          disabled={disabledAdd}
          onClick={addFolder}
        >
          New Folder
        </AddNew>
      </SidebarFooter>
      <RenameModal />
      <DeleteFolderModal />
      <ArchiveProjectModal />
    </Container>
  );
};

const Container = styled.div`
  width: 351px;
  min-height: calc(100vh - 95px);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.12);
  position: relative;
`;

const SidebarHeader = styled.div`
  width: 351px;
  height: 48px;
  border-bottom: 1px solid #f1f2f4;
  display: flex;
  align-items: center;
  padding-left: 14px;
  box-sizing: border-box;
`;

const IconImg = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  cursor: pointer;
`;

const SidebarFooter = styled.div`
  position: absolute;
  width: 351px;
  height: 83px;
  left: 0px;
  bottom: 0px;
  border-top: 1px solid #f1f2f4;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddNew = styled(Button)`
  width: 286px;
`;

export default Sidebar;
