import React from 'react';
import { Button, ButtonGroup, Typography } from 'cwb-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useFolders } from 'contexts';

function TableHeader({ selected, clearSelection }) {
  const { t } = useTranslation();
  const {
    folderPath,
    selectedFolder,
    setSelectedFolder,
    setDisabledAdd,
    setSelectedFolderPath,
    getIncludeFolders,
    setIncludeFolders
  } = useFolders();
  const changeLocationTop = (id, name) => {
    setSelectedFolder(id);
    setSelectedFolderPath(id);
    getIncludeFolders(id);
    setDisabledAdd(false);
  };
  const changeLocationSub = (id, name, parent) => {
    setSelectedFolder(id);
    setSelectedFolderPath(id);
    getIncludeFolders(id);
    setDisabledAdd(false);
  };
  const backToAll = () => {
    setSelectedFolder(null);
    setSelectedFolderPath(null);
    setIncludeFolders(null);
    setDisabledAdd(false);
  };
  const Path = () => {
    if (selectedFolder === null && folderPath === null) {
      return (
        <StyledTypography color="medGrey">{t('All Projects')}</StyledTypography>
      );
    } else if (folderPath !== null && selectedFolder !== null) {
      const { current, parent, ancestor } = folderPath;
      if (
        parent === undefined &&
        ancestor === undefined &&
        current !== undefined
      ) {
        return (
          <PathContainer>
            <HeadTypography color="medGrey" onClick={backToAll}>
              {t('All Projects')}
            </HeadTypography>
            <StyledTypography>/</StyledTypography>
            <HeadTypography color="medGrey">{current.name}</HeadTypography>
          </PathContainer>
        );
      } else if (
        parent !== undefined &&
        ancestor === undefined &&
        current !== undefined
      ) {
        return (
          <PathContainer>
            <HeadTypography color="medGrey" onClick={backToAll}>
              {t('All Projects')}
            </HeadTypography>
            <StyledTypography>/</StyledTypography>
            <HeadTypography
              color="medGrey"
              onClick={() => changeLocationTop(parent.id, parent.name)}
            >
              {parent.name}
            </HeadTypography>
            <StyledTypography>/</StyledTypography>
            <HeadTypography color="medGrey">{current.name}</HeadTypography>
          </PathContainer>
        );
      } else if (
        parent !== undefined &&
        ancestor !== undefined &&
        current !== undefined
      ) {
        return (
          <PathContainer>
            <HeadTypography color="medGrey" onClick={backToAll}>
              {t('All Projects')}
            </HeadTypography>
            <StyledTypography>/</StyledTypography>
            <HeadTypography
              color="medGrey"
              onClick={() => changeLocationTop(ancestor.id, ancestor.name)}
            >
              {ancestor.name}
            </HeadTypography>
            <StyledTypography>/</StyledTypography>
            <HeadTypography
              color="medGrey"
              onClick={() =>
                changeLocationSub(parent.id, parent.name, ancestor.id)
              }
            >
              {parent.name}
            </HeadTypography>
            <StyledTypography>/</StyledTypography>
            <HeadTypography color="medGrey">{current.name}</HeadTypography>
          </PathContainer>
        );
      }
    }
  };
  const length = selected.length;

  return (
    <StyledDiv>
      <div>{Path()}</div>
      <div>
        {Boolean(selected.length) && (
          <>
            <StyledTypography color="medGrey" variant="bodyBold">
              {t('itemSelected', { length })}
            </StyledTypography>
            <ButtonGroup>
              <Button variant="secondary" onClick={clearSelection}>
                {t('Clear Selection')}
              </Button>
              <Button>{t('Archive')}</Button>
            </ButtonGroup>
          </>
        )}
      </div>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: ${(p) => p.theme.spacing(1)};

  & > div {
    height: 35px;
    display: flex;
    align-items: center;
  }
`;

const StyledTypography = styled(Typography)`
  margin-right: ${(p) => p.theme.spacing(1)};
`;

const HeadTypography = styled(Typography)`
  margin-right: ${(p) => p.theme.spacing(1)};
  cursor: pointer;
`;

const PathContainer = styled.div`
  display: flex;
`;
export default TableHeader;
