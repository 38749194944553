import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Typography } from 'cwb-react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/Modal';
import { useFolders, useProjects } from 'contexts';

const DeleteFolderModal = () => {
  const { t } = useTranslation();
  const {
    deleteModalOpen,
    setDeleteModelOpen,
    deleteFolder,
    confirmDelete,
    fetchIncludeFolders
  } = useFolders();
  const { unsortProjects } = useProjects();

  const [folderName, setFolderName] = useState('');

  useEffect(() => {
    if (deleteFolder !== null) {
      setFolderName(deleteFolder.name);
    }
  }, [deleteFolder]);

  const confirm = () => {
    confirmDelete(deleteFolder);
    const idArray = fetchIncludeFolders(deleteFolder.id);
    unsortProjects(idArray);
    setDeleteModelOpen(false);
  };

  return (
    <Modal isOpen={deleteModalOpen} onClose={() => setDeleteModelOpen(false)}>
      <ModalHeader destructive onClose={() => setDeleteModelOpen(false)}>
        {t('Delete Folder')}
      </ModalHeader>
      <ModalBody>
        <Typography component="span">
          {t('Are you sure you want to delete the folder')}
          &nbsp;
          <Typography component="span" variant="bodyBold">
            {folderName}
          </Typography>
          ?
        </Typography>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button destructive onClick={confirm}>
            {t('Delete Folder')}
          </Button>
          <Button
            destructive
            variant="tertiary"
            onClick={() => setDeleteModelOpen(false)}
          >
            {t('Cancel')}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteFolderModal;
