import React from 'react';
import styled from 'styled-components';

function Page({ children, className }) {
  return <StyledDiv className={className}>{children}</StyledDiv>;
}

const StyledDiv = styled.div`
  min-height: calc(100vh - 95px);
`;

export default Page;
