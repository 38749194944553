import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Typography } from 'cwb-react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/Modal';
import { useProjects } from 'contexts';

const ArchiveProjectModal = () => {
  const { t } = useTranslation();
  const {
    archiveModalOpen,
    setArchiveModelOpen,
    archiveProject,
    archive,
    setArchiveProject
  } = useProjects();

  const [projectTitle, setProjectTitle] = useState('');

  useEffect(() => {
    if (archiveProject !== null) {
      setProjectTitle(archiveProject.title);
    }
  }, [archiveProject]);

  // delete from initial for this moment
  const confirm = () => {
    setArchiveModelOpen(false);
    archive(archiveProject.id);
    setArchiveProject(null);
  };

  return (
    <Modal isOpen={archiveModalOpen} onClose={() => setArchiveModelOpen(false)}>
      <ModalHeader destructive onClose={() => setArchiveModelOpen(false)}>
        {t('Archive Project')}
      </ModalHeader>
      <ModalBody>
        <Typography component="span">
          {t('Are you sure you want to archive')}
          &nbsp;
          <Typography component="span" variant="bodyBold">
            {projectTitle}
          </Typography>
          ?
        </Typography>
        <Typography>
          {t('It will still be available in Archived Projects')}
        </Typography>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button destructive onClick={confirm}>
            {t('Archive')}
          </Button>
          <Button
            destructive
            variant="tertiary"
            onClick={() => setArchiveModelOpen(false)}
          >
            {t('Cancel')}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default ArchiveProjectModal;
