import React, { useEffect, useState } from 'react';
import { Select, Typography } from 'cwb-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useProjects, useFolders } from 'contexts';
import LeftArrow from 'images/caret_left.svg';
import RightArrow from 'images/caret_right.svg';

const limitOptions = [
  { label: '3', value: 3 },
  { label: '5', value: 5 },
  { label: '10', value: 10 }
  // { label: '15', value: 15 },
  // { label: '30', value: 30 },
  // { label: '50', value: 50 }
];

function TableFooter() {
  const [limit, setLimit] = useState(limitOptions[2]);
  const [offset, setOffset] = useState(0);

  const { t } = useTranslation();
  const { total, fetchProjects } = useProjects();
  const { includeFolders } = useFolders();

  useEffect(() => {
    fetchProjects(limit.value, offset, includeFolders);
  }, [limit.value, offset, fetchProjects, includeFolders]);

  const previousPage = () => {
    setOffset((currentOffset) => {
      if (currentOffset - limit.value < 0) return 0;
      return currentOffset - limit.value;
    });
  };

  const nextPage = () => {
    setOffset((currentOffset) => {
      return currentOffset + limit.value;
    });
  };

  const isOnFirstPage = offset === 0;
  const isOnLastPage = offset + limit.value >= total;
  const itemRangeLower = offset + 1;
  const itemRangeUpper =
    offset + limit.value > total ? total : offset + limit.value;

  return (
    <StyledDiv>
      <TableOptionsContainer>
        <Typography color="medGrey">{t('Rows per page')}:</Typography>
        <StyledSelect
          value={limit}
          options={limitOptions}
          onSelect={setLimit}
        />
      </TableOptionsContainer>
      <div>
        <Typography color="medGrey">
          {t('pagination', { itemRangeLower, itemRangeUpper, total })}
        </Typography>
      </div>
      <NavigationContainer>
        <StyledButton disabled={isOnFirstPage} onClick={previousPage}>
          <Icon src={LeftArrow} />
        </StyledButton>
        <StyledButton disabled={isOnLastPage} onClick={nextPage}>
          <Icon src={RightArrow} />
        </StyledButton>
      </NavigationContainer>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: ${(p) => p.theme.spacing(1)};
`;

const TableOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${(p) => p.theme.spacing(2)};
`;

const StyledSelect = styled(Select)`
  & > button {
    border: none;
  }
`;

const NavigationContainer = styled.div`
  display: flex;
  margin-left: ${(p) => p.theme.spacing(2)};
`;

const StyledButton = styled.button`
  width: 16px;
  height: 16px;
  padding: ${(p) => p.theme.spacing(1)};
  background-color: ${(p) => p.theme.palette.common.white};
  border: none;
  box-sizing: content-box;

  &:disabled {
    opacity: 0.3;
  }
`;

const Icon = styled.img`
  margin-bottom: ${(p) => p.theme.spacing('10px')};
`;

export default TableFooter;
