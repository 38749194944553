import React, { createContext, useContext, useState, useEffect } from 'react';

const FolderContext = createContext({});

const initialFolders = [
  {
    id: 1,
    name: 'Better Call Saul',
    folders: [
      {
        id: 2,
        name: 'Season 1',
        folders: [
          {
            id: 3,
            name: 'Episode 1',
            folders: []
          }
        ]
      }
    ]
  },
  {
    id: 4,
    name: 'The Boys',
    folders: [
      {
        id: 5,
        name: 'Season 1',
        folders: []
      },
      {
        id: 6,
        name: 'Season 2',
        folders: []
      }
    ]
  },
  {
    id: 7,
    name: 'Vancouver based',
    folders: [
      {
        id: 8,
        name: 'Dark Window',
        folders: [
          {
            id: 10,
            name: 'Season 1',
            folders: []
          }
        ]
      },
      {
        id: 9,
        name: 'Yellowstone',
        folders: []
      }
    ]
  },
  {
    id: 11,
    name: 'The 100',
    folders: []
  },
  {
    id: 12,
    name: 'The Chosen',
    folders: []
  }
];

export const FolderProvider = ({ children }) => {
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [disabledAdd, setDisabledAdd] = useState(false);
  const [addToFolder, setAddToFolder] = useState(null);
  const [addTimer, setAddTimer] = useState(null);
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [renameFolder, setRenameFolder] = useState(null);
  const [deleteModalOpen, setDeleteModelOpen] = useState(false);
  const [deleteFolder, setDeleteFolder] = useState(null);
  const [folders, setFolders] = useState(initialFolders);
  const [openFolder, setOpenFolder] = useState(null);
  const [openTimer, setOpenTimer] = useState(null);
  const [expandedFolder, setExpandedFolder] = useState([]);
  const [folderPath, setFolderPath] = useState(null);
  const [includeFolders, setIncludeFolders] = useState(null);

  const pureDelete = (arr, i) => {
    if (i >= 0) {
      return [...arr.slice(0, i), ...arr.slice(i + 1)];
    } else {
      return arr;
    }
  };

  const purePush = (arr, el) => [...arr, el];

  const confirmDelete = ({ id, parent, ancestor }) => {
    if (parent === null && ancestor === null) {
      setFolders((folders) => {
        const index = folders.findIndex((item) => item.id === id);
        return pureDelete(folders, index);
      });
    }
    if (parent !== null && ancestor === null) {
      setFolders((folders) => {
        return folders.map((item) => {
          if (item.id === parent) {
            const index = item.folders.findIndex((sub) => sub.id === id);
            const newFolders = pureDelete(item.folders, index);
            return {
              ...item,
              folders: newFolders
            };
          } else {
            return item;
          }
        });
      });
    }
    if (parent !== null && ancestor !== null) {
      setFolders((folders) => {
        return folders.map((item) => {
          if (item.id === ancestor) {
            const subFolders = item.folders;

            const newSubFolders = subFolders.map((sub) => {
              if (sub.id === parent) {
                const childFolders = sub.folders;
                const index = childFolders.findIndex((item) => item.id === id);
                const newChildFolders = pureDelete(childFolders, index);
                return {
                  ...sub,
                  folders: newChildFolders
                };
              } else {
                return sub;
              }
            });

            return {
              ...item,
              folders: newSubFolders
            };
          } else {
            return item;
          }
        });
      });
    }

    if (fetchFolderPath(selectedFolder).includes(id)) {
      setSelectedFolderPath(null);
      setSelectedFolder(null);
      setIncludeFolders(null);
    }
  };

  const moveDelete = ({ id, parent, ancestor }) => {
    if (parent === null && ancestor === null) {
      setFolders((folders) => {
        const index = folders.findIndex((item) => item.id === id);
        return pureDelete(folders, index);
      });
    }
    if (parent !== null && ancestor === null) {
      setFolders((folders) => {
        return folders.map((item) => {
          if (item.id === parent) {
            const index = item.folders.findIndex((sub) => sub.id === id);
            const newFolders = pureDelete(item.folders, index);
            return {
              ...item,
              folders: newFolders
            };
          } else {
            return item;
          }
        });
      });
    }
    if (parent !== null && ancestor !== null) {
      setFolders((folders) => {
        return folders.map((item) => {
          if (item.id === ancestor) {
            const subFolders = item.folders;

            const newSubFolders = subFolders.map((sub) => {
              if (sub.id === parent) {
                const childFolders = sub.folders;
                const index = childFolders.findIndex((item) => item.id === id);
                const newChildFolders = pureDelete(childFolders, index);
                return {
                  ...sub,
                  folders: newChildFolders
                };
              } else {
                return sub;
              }
            });

            return {
              ...item,
              folders: newSubFolders
            };
          } else {
            return item;
          }
        });
      });
    }
  };

  const confirmRename = ({ id, parent, ancestor }, name) => {
    if (parent === null && ancestor === null) {
      setFolders((folders) => {
        return folders.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              name
            };
          } else {
            return item;
          }
        });
      });
    }
    if (parent !== null && ancestor === null) {
      setFolders((folders) => {
        return folders.map((item) => {
          if (item.id === parent) {
            const subFolders = item.folders;

            const newSubFolders = subFolders.map((sub) => {
              if (sub.id === id) {
                return {
                  ...sub,
                  name
                };
              } else {
                return sub;
              }
            });

            return {
              ...item,
              folders: newSubFolders
            };
          } else {
            return item;
          }
        });
      });
    }
    if (parent !== null && ancestor !== null) {
      setFolders((folders) => {
        return folders.map((item) => {
          if (item.id === ancestor) {
            const subFolders = item.folders;

            const newSubFolders = subFolders.map((sub) => {
              if (sub.id === parent) {
                const childFolders = sub.folders;
                const newChildFolders = childFolders.map((child) => {
                  if (child.id === id) {
                    return {
                      ...child,
                      name
                    };
                  } else {
                    return child;
                  }
                });
                return {
                  ...sub,
                  folders: newChildFolders
                };
              } else {
                return sub;
              }
            });

            return {
              ...item,
              folders: newSubFolders
            };
          } else {
            return item;
          }
        });
      });
    }
  };

  const confirmAdd = ({ name, parent, ancestor }) => {
    if (parent === null && ancestor === null) {
      const id = Math.floor(Math.random() * 100) + 20;
      const newFolder = {
        id,
        name,
        folders: []
      };
      setFolders((folders) => {
        return purePush(folders, newFolder);
      });
    }
    if (parent !== null && ancestor === null) {
      const id = Math.floor(Math.random() * 100) + 20;
      const newFolder = {
        id,
        name,
        folders: []
      };
      setFolders((folders) => {
        return folders.map((item) => {
          if (item.id === parent) {
            const newSubFolder = purePush(item.folders, newFolder);
            return {
              ...item,
              folders: newSubFolder
            };
          } else {
            return item;
          }
        });
      });
    }
    if (parent !== null && ancestor !== null) {
      const id = Math.floor(Math.random() * 100) + 20;
      const newFolder = {
        id,
        name,
        folders: []
      };
      setFolders((folders) => {
        return folders.map((item) => {
          if (item.id === ancestor) {
            const subFolders = item.folders;

            const newSubFolders = subFolders.map((sub) => {
              if (sub.id === parent) {
                const childFolders = sub.folders;
                const newChildFolders = purePush(childFolders, newFolder);
                return {
                  ...sub,
                  folders: newChildFolders
                };
              } else {
                return sub;
              }
            });

            return {
              ...item,
              folders: newSubFolders
            };
          } else {
            return item;
          }
        });
      });
    }
  };

  const dropOn = ({ parent, ancestor }, newFolder) => {
    if (parent === null && ancestor === null) {
      setFolders((folders) => {
        return purePush(folders, newFolder);
      });
    }
    if (parent !== null && ancestor === null) {
      setFolders((folders) => {
        return folders.map((item) => {
          if (item.id === parent) {
            const newSubFolder = purePush(item.folders, newFolder);
            return {
              ...item,
              folders: newSubFolder
            };
          } else {
            return item;
          }
        });
      });
    }
    if (parent !== null && ancestor !== null) {
      setFolders((folders) => {
        return folders.map((item) => {
          if (item.id === ancestor) {
            const subFolders = item.folders;

            const newSubFolders = subFolders.map((sub) => {
              if (sub.id === parent) {
                const childFolders = sub.folders;
                const newChildFolders = purePush(childFolders, newFolder);
                return {
                  ...sub,
                  folders: newChildFolders
                };
              } else {
                return sub;
              }
            });

            return {
              ...item,
              folders: newSubFolders
            };
          } else {
            return item;
          }
        });
      });
    }
  };

  const confirmMove = (item, target) => {
    const current = item.folder;
    moveDelete({
      id: item.folder.id,
      parent: item.parent,
      ancestor: item.ancestor
    });
    dropOn({ parent: target.parent, ancestor: target.ancestor }, current);
    if (selectedFolder === item.folder.id) {
      setSelectedFolderPath(selectedFolder);
      setOpenFolder(selectedFolder);
    }
  };

  const confirmOuterMove = (item, target) => {
    const current = item.folder;
    moveDelete({
      id: item.folder.id,
      parent: item.parent,
      ancestor: item.ancestor
    });
    dropOn({ parent: target.parent, ancestor: target.ancestor }, current);
  };

  useEffect(() => {
    setSelectedFolderPath(selectedFolder);
    setOpenFolder(selectedFolder);
    getIncludeFolders(selectedFolder);
  }, [folders, selectedFolder]);

  const setSelectedFolderPath = (id) => {
    if (id === null) {
      setFolderPath(null);
    } else {
      const top = folders.find((el) => el.id === id);
      if (top !== undefined) {
        const current = {
          id,
          name: top.name
        };
        setFolderPath({ current });
        return;
      } else {
        for (let i = 0; i < folders.length; i++) {
          const idArray = fetchSubIds(folders[i]);
          if (idArray.includes(id)) {
            const parentId = folders[i].id;
            const parentName = folders[i].name;
            const target = folders[i].folders.find((el) => el.id === id);
            const current = {
              id,
              name: target.name
            };
            const parent = {
              id: parentId,
              name: parentName
            };
            setFolderPath({ current, parent });
            return;
          } else {
            const subFolders = folders[i].folders;
            for (let j = 0; j < subFolders.length; j++) {
              const subIdArray = fetchSubIds(subFolders[j]);
              if (subIdArray.includes(id)) {
                const parentId = subFolders[j].id;
                const parentName = subFolders[j].name;
                const target = subFolders[j].folders.find((el) => el.id === id);
                const current = {
                  id,
                  name: target.name
                };
                const parent = {
                  id: parentId,
                  name: parentName
                };
                const ancestor = {
                  id: folders[i].id,
                  name: folders[i].name
                };
                setFolderPath({ current, parent, ancestor });
                return;
              }
            }
          }
        }
      }
      setFolderPath(null);
    }
  };
  const fetchFolderPath = (id) => {
    let result = [];
    if (id === null) {
      return result;
    } else {
      const top = folders.find((el) => el.id === id);
      if (top !== undefined) {
        result.push(id);
        return result;
      } else {
        for (let i = 0; i < folders.length; i++) {
          const idArray = fetchSubIds(folders[i]);
          if (idArray.includes(id)) {
            const parentId = folders[i].id;
            result.push(id);
            result.push(parentId);
            return result;
          } else {
            const subFolders = folders[i].folders;
            for (let j = 0; j < subFolders.length; j++) {
              const subIdArray = fetchSubIds(subFolders[j]);
              if (subIdArray.includes(id)) {
                const parentId = subFolders[j].id;
                result.push(id);
                result.push(parentId);
                result.push(folders[i].id);
                return result;
              }
            }
          }
        }
      }
      return result;
    }
  };

  const getNameTop = (id) => {
    const target = folders.find((item) => item.id === id);
    if (target !== undefined) {
      return target.name;
    } else {
      return null;
    }
  };

  const getNameSub = (ancestor, parent) => {
    const ancestorFolder = folders.find((item) => item.id === ancestor);
    if (ancestorFolder !== undefined) {
      const subFolder = ancestorFolder.folders.find(
        (item) => item.id === parent
      );
      if (subFolder !== undefined) {
        return subFolder.name;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const fetchSubIds = (folder) => {
    let result = [];
    if (folder.folders !== undefined) {
      for (let i = 0; i < folder.folders.length; i++) {
        result.push(folder.folders[i].id);
      }
      return result;
    } else {
      return [];
    }
  };

  const getIncludeFolders = (id) => {
    if (id === null) {
      setIncludeFolders(null);
    } else {
      const subIds = fetchIncludeFolders(id);
      setIncludeFolders(subIds);
    }
  };
  const fetchIncludeFolders = (id) => {
    if (id === null) {
      return [];
    } else {
      const targetFolder = folders.find((el) => el.id === id);
      if (targetFolder !== undefined) {
        let subIds = [id];
        subIds = subIds.concat(fetchSubIds(targetFolder));
        for (let i = 0; i < targetFolder.folders.length; i++) {
          subIds = subIds.concat(fetchSubIds(targetFolder.folders[i]));
        }
        return subIds;
      } else {
        for (let i = 0; i < folders.length; i++) {
          const idArray = fetchSubIds(folders[i]);
          if (idArray.includes(id)) {
            const subFolder = folders[i].folders.find((el) => el.id === id);
            let subIds = [id];
            subIds = subIds.concat(fetchSubIds(subFolder));
            setIncludeFolders(subIds);
            return subIds;
          }
        }
      }
      let subIds = [id];
      return subIds;
    }
  };

  return (
    <FolderContext.Provider
      value={{
        folders,
        selectedFolder,
        setSelectedFolder,
        disabledAdd,
        setDisabledAdd,
        addToFolder,
        setAddToFolder,
        addTimer,
        setAddTimer,
        renameModalOpen,
        setRenameModalOpen,
        renameFolder,
        setRenameFolder,
        deleteModalOpen,
        setDeleteModelOpen,
        deleteFolder,
        setDeleteFolder,
        confirmDelete,
        confirmRename,
        confirmAdd,
        confirmMove,
        confirmOuterMove,
        openFolder,
        setOpenFolder,
        expandedFolder,
        setExpandedFolder,
        openTimer,
        setOpenTimer,
        folderPath,
        setSelectedFolderPath,
        getNameTop,
        getNameSub,
        includeFolders,
        getIncludeFolders,
        setIncludeFolders,
        fetchIncludeFolders
      }}
    >
      {children}
    </FolderContext.Provider>
  );
};

export function useFolders() {
  return useContext(FolderContext);
}
