import React, { useState } from 'react';
import { Button, ButtonGroup, Select, TextInput, Typography } from 'cwb-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useFolders, useProjects } from 'contexts';

function HeaderContent() {
  const { t } = useTranslation();

  const viewOptions = [
    {
      label: t('All Projects'),
      value: 'all'
    },
    {
      label: t('Unsorted Projects'),
      value: 'unsorted'
    },
    {
      label: t('Archived Projects'),
      value: 'archived'
    }
  ];

  const [viewOption, setViewOption] = useState(viewOptions[0]);
  const [searchText, setSearchText] = useState('');
  const { setSelectedFolder, setSelectedFolderPath, setIncludeFolders } =
    useFolders();
  const { setSearch } = useProjects();

  const searchProject = () => {
    if (searchText !== '') {
      setSearch(searchText);
      setSelectedFolder(null);
      setSelectedFolderPath(null);
      setIncludeFolders(null);
    } else {
      setSearch(searchText);
    }
  };

  const keyOperation = (event) => {
    if (event.key === 'Enter' && searchText !== '') {
      setSearch(searchText);
      setSelectedFolder(null);
      setSelectedFolderPath(null);
      setIncludeFolders(null);
    }
  };

  return (
    <StyledDiv>
      <Typography variant="bodyBold">{t('View')}:&nbsp;</Typography>
      <StyledSelect
        value={viewOption}
        options={viewOptions}
        onSelect={setViewOption}
      />
      <StyledTextInput
        placeholder={t('Search projects...')}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
        onKeyPress={keyOperation}
        value={searchText}
      />
      <ButtonGroup>
        <Button onClick={searchProject}>{t('Search')}</Button>
        <Button variant="secondary">{t('Upload')}</Button>
        <Button variant="secondary">{t('Add Project')}</Button>
      </ButtonGroup>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  margin-left: ${(p) => p.theme.spacing(4)};
`;

const StyledSelect = styled(Select)`
  width: 170px;
`;

const StyledTextInput = styled(TextInput)`
  flex-grow: 1;
  max-width: 460px;
  margin: ${(p) => p.theme.spacing(0, 1)};
`;

export default HeaderContent;
