import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, toast } from 'cwb-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useFolders } from 'contexts';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/Modal';

const RenameModal = () => {
  const { t } = useTranslation();
  const { renameModalOpen, setRenameModalOpen, renameFolder, confirmRename } =
    useFolders();

  const [renameText, setRenameText] = useState('');

  useEffect(() => {
    if (renameFolder !== null) {
      setRenameText(renameFolder.name);
    }
  }, [renameFolder]);

  const confirm = async () => {
    const previous = {
      ...renameFolder,
      renameText
    };
    confirmRename(renameFolder, renameText);
    setRenameModalOpen(false);
    launchToast(previous);
  };

  const launchToast = (previous) => {
    let toastId;

    const options = { autoClose: false };

    const { id, name, parent, ancestor } = previous;

    // define the toast content as a ReactNode
    const toastInner = () => {
      return (
        <Container>
          "{name}" renamed to "{renameText}"
          <UndoButton
            onClick={() => {
              confirmRename({ id, parent, ancestor }, name);
              toast.update(toastId, {
                render: t('Action Undone'),
                type: toast.TYPE.INFO,
                autoClose: 2000
              });
            }}
          >
            {t('UNDO')}
          </UndoButton>
        </Container>
      );
    };
    /*  const toastInner = <RenameToaster/> */

    toastId = toast.info(toastInner, options);
  };

  return (
    <Modal isOpen={renameModalOpen} onClose={() => setRenameModalOpen(false)}>
      <ModalHeader onClose={() => setRenameModalOpen(false)}>
        {t('Rename Folder')}
      </ModalHeader>
      <ModalBody>
        <NameInput
          style={{ maxWidth: 405 }}
          value={renameText}
          onChange={(e) => {
            setRenameText(e.target.value);
          }}
        />
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button onClick={confirm}>{t('Rename')}</Button>
          <Button variant="secondary" onClick={() => setRenameModalOpen(false)}>
            {t('Cancel')}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

const NameInput = styled.input`
  width: 100%;
  height: 35px;
  display: block;
  padding: 0px 12px 0px 12px;
  color: #364866;
  background-color: #ffffff;
  border: 1px solid #d1d5dd;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  outline: none;

  &::placeholder {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #8a94a6;
  }

  &:focus {
    border-color: ${(p) => p.theme.palette.button.normal};
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  white-space: nowrap;
  overflow: hidden;
`;

const UndoButton = styled.button`
  color: #0f9bf0;
  background-color: white;
  border: none;
  margin-left: 16px;
  font-size: 14px;
  font-weight: 600;
`;

export default RenameModal;
